var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-entity-container',_vm._b({},'v-entity-container',{
      title: _vm.title,
      metricFilters: _vm.metricFilters,
      metricFiltersQuery: _vm.metricFiltersQuery,
      activeProjectId: _vm.activeProjectId,
      data: _vm.sortedCardList,
      tableCellsConfig: _vm.filteredTableCellsConfig || _vm.tableCellsConfig,
      page: 'card',
      label: 'Карточки'
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }