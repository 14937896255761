<template>
  <v-entity-container
      v-bind="{
        title,
        metricFilters,
        metricFiltersQuery,
        activeProjectId,
        data: sortedCardList,
        tableCellsConfig: filteredTableCellsConfig || tableCellsConfig,
        page: 'card',
        label: 'Карточки'
      }"
  />
</template>

<script>
import {mapState} from 'vuex'
import filterWSByProject from '@/mixins/filterWSByProject.js'

import entityContainer from '@/services/components/entityContainer'

export default {
  name: 'cards',

  components: {
    'v-entity-container': entityContainer
  },
  mixins: [filterWSByProject],

  data () {
    return {
      title: {
        primary: 'Data analytics',
        secondary: 'Products'
      }
    }
  },

  computed: {
    ...mapState({
      cardList: state => state.dashboard.cardList
    }),

    tableCellsConfig() {
      return this.$store.getters['tableSettings/getTableCellsConfigCard']
    },

    sortedCardList () {
      return {
        has_data: this.cardList.has_data,
        items: [...this.cardList.items].sort((a, b) => b.count_all - a.count_all),
        total: this.cardList.total
      }
    },

    metricFilters () {
      let items = [
        'ws2',
        'top3',
        'top5',
        'top10',
        'p_traf',
        'sessions',
        'users',
        'new_users',
        'transactions',
        'conversions',
        'transaction_revenue',
        'count_questions',
        'number_of_answers',
        'number_of_comments',
        'rating',
        'count_positive_review',
        'count_negative_review',
        'length_most_helpful_review',
        'count_photos',
        'price',
        'count_review',
        'count_videos',
        'count_video_360',
        'merchant_is_marketplace',
        'product_detail_views',
        'product_adds_to_cart',
        'product_checkouts',
        'item_quantity',
        'item_revenue'
      ]
      if (!this.shouldFilterWSMetrics)  {
        items.push('ws1')
        items.push('ws3')
      }
      return items
    },

    metricFiltersQuery () {
      let filters = []

      this.metricFilters.forEach(filter => {
        filters.push(`${filter}_from`)
        filters.push(`${filter}_to`)
        filters.push(`${filter}_diff_from`)
        filters.push(`${filter}_diff_to`)
      })

      return filters
    },
  }
}
</script>
